@charset "UTF-8";

@import "definitions";

$tile_size_tb: 14.5vw;
$tile_size_sp: 20vw;

@media screen and (max-width: 768px) {

  /* structure
====================================================================================================================================== */
  body {
    min-width: initial;
  }
  #container{
    position: relative;
  }
  .wrapper {
    margin: 0;
    padding-left: 25px;
    padding-right: 25px;
    width: auto!important;
  }
  #navibar{
    padding-left: 25px;
  }

  /* 中ページ
  ====================================================================================================================================== */
  #page_title_wrap{
    background-position: left top;
    background-size: $tile_size_tb $tile_size_tb;
    min-height: calc(#{$tile_size_tb} * 2.7);
    padding-top: 12vw;
    align-items: flex-start;
  }
  #navibar{
   padding: 17px 10px 0 calc(#{$tile_size_tb} * 2 + 10px); 
  }

  /* ページ内リンク
  ====================================================================================================================================== */
  #page_links{
    padding-top: 25px;
    ul{
      column-gap: 2em;
    }
  }


  /* header
  ====================================================================================================================================== */
  #header{
    position: relative;
    &:before{
      content: "";
      height: 7px;
      background-color: $origin_color;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
    }
  }
  #site_title {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(#{$tile_size_tb} * 2);
    height: $tile_size_tb;
    padding-top: 7px;
    z-index: 1;
    font-size: 3.8vw;
    line-height: 1.15;
    &.top{
      height: auto;
      aspect-ratio: 1;
    }
    a{
      display: grid;
      place-items: center;
      height: 100%;
    }
    .inner{
      display: block;
      span{
        display: block;
      }
    }
    .ja{
      font-size: 0.4em;
    }
  }


  /* スマホ用メニュー
  ====================================================================================================================================== */
  #sp_menu_area{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: $zindex_max - 20;
    display: flex;
    gap: 10px;
    .link_recruit{
      a{
        display: grid;
        place-items: center;
        height: 100%;
        border-radius: 2em;
        background-color: $blue;
        color: #fff;
        font-family: $font_teko;
        font-size: 1.625rem;
        text-decoration: none;
        line-height: 1;
        letter-spacing: 0.01em;
        padding: 0 1.3em;
      }
    }
    #sp_menu_button{
      width: 44px;
      img{
        width: 100%;
      }
    }
  }

  #sp_menu_bg{
    position: fixed;
    top: 0;
    left: 0;
    z-index: #{$zindex_max - 2};
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: $origin_color, $alpha: 0.1);
    &.menu_opened{
      display: block;
    }
  }

  /* globalnavi
  ====================================================================================================================================== */
  #globalnavi{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $zindex_max;
    width: 100%;
    translate: 0 100%;
    transition: .5s;
    background-color: $origin_color;
    border-top: 1px solid #fff;
    &.menu_opened{
      translate: 0;
      box-shadow: 0 0 50px rgba($color: #000, $alpha: 0.2);
    }
    ul.nav_list{
      grid-template-columns: repeat(4, 1fr);
      gap: 1px;
      background-color: #fff;
      li{
        width: auto;
        font-size: 3.5vw;
        .inner{
          padding: 15% 0 0 15%;
        }
      }
      &:after {
        content: "";
        display: block;
        background-color: $origin_color;
        grid-row: 2;
        grid-column: 3 / span 2;
      }
    }
    .sp_menu_close{
      border-top: 1px solid #fff;
      height: calc(44px + 20px * 2);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      a{
        display: block;
        width: 44px;
        img{
          width: 100%;
        }
      }
    }
  }
  

/* クッキーの取り扱いについて
====================================================================================================================================== */
#cookie_acceptance{
  .wrapper{
    display: block;
  }
  h2{
    margin-bottom: 0.3em;
  }
  p{
    width: auto;
    margin-bottom: 0.9em;
  }
  .button_area{
    width: 120px;
    margin: 0 auto;
  }
}

/* トップページ loading
====================================================================================================================================== */
#loading{
  min-width: initial;
}
#loading,
#main_visual{
  background-size: $tile_size_tb $tile_size_tb;
  background-position: left top;
}
#loading_catch_area,
#main_visual{
  height: 100svh;
  //transition: 1s;
}

#loading_catch_area,
#main_visual{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 115px;
  .catch_copy{
    .en{
      font-size: 14.5vw;
    }
    .ja{
      font-size: 2.9vw;
    }
  }
}

/* トップページ main visual
====================================================================================================================================== */
#main_visual{
  .circle_text{
    inset: auto 0 0 auto;
    translate: 45% 25%;
    width: unquote('min(140vw, 880px)');
  }
}


  /* contents
====================================================================================================================================== */
#conts{
  padding-bottom: 150px;
}

#solutions{
  padding: 9em 0 70vw;　
  .wrapper{
    padding: 0 35px;
  }
  .text_area{
    width: auto;
    p{
      width: auto;
      margin-bottom: 0;
    }
    .lead_text{
      font-size: 1.9125rem;
      width: auto;
      margin-bottom: 1.1em;
    }
  }
  $solution_radius_tb: 45vw;
  .solutions_title_area{
    width: #{$solution_radius_tb * 2};
    .bottom_text{
      right: calc(100% - 8.2vw);
      bottom: auto;
      top: -9vw;
      font-size: 31vw;
      line-height: 0.85;
    }
    h2{
      font-size: 5.6vw;
    }
    ul{
      li{
        font-size: 3.3vw;
        &:nth-child(1){
          left: calc(cos(-5deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
          bottom: calc(sin(-5deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
        }
        &:nth-child(2){
          left: calc(cos(-29deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
          bottom: calc(sin(-29deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
        }
        &:nth-child(3){
          left: calc(cos(-53deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
          bottom: calc(sin(-53deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
        }
        &:nth-child(4){
          left: calc(cos(-77deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
          bottom: calc(sin(-77deg) * #{$solution_radius_tb} * -1 + #{$solution_radius_tb});
        }
      }
    }
  }
}

#service{
  border-top: 1px solid $origin_color;
  .wrapper{
    display: block;
  }
  .side_area {
    display: block;
    padding: 8em 0 4em;
    border: none;
    &:before{
      content: "";
      display: block;
      width: 270px;
      aspect-ratio: 600/685;
      background: url(../images/japan.png) no-repeat center bottom;
      background-size: contain;
      position: absolute;
      bottom: -30px;
      right: -40px;
    }
    .top_title{
      display: flex;
      align-items: center;
    }
    .lead_text{
      display: block;
      position: static;
      width: auto;
      height: auto;
      p{
        width: auto;
        font-size: 1.2rem;
        font-weight: bold;
        &:before{
          display: none;
        }
      }
    }
  }
  .service_link{
    height: 390px;
    border: {
      left: 1px solid $origin_color;
      right: 1px solid $origin_color;
    }
    &:first-of-type{
      border-top: 1px solid $origin_color;
    }
    a, .no_link{
      display: block;
      padding: 110px 95px 0;
    }
    a{
      &:after{
        content: "";
        display: block;
        width: 21px;
        aspect-ratio: 1;
        background: url(../images/icon_arrow_white_rb.svg) no-repeat right bottom;
        background-size: contain;
        position: absolute;
        bottom: 8px;
        right: 8px;
      }
    }
    .num{
      font-size: 1.275rem;
    }
    .english_title{
      font-size: 11.5rem;
      right: 30px;
    }
    .text_area{
      width: 100%;
      h3{
        font-size: 2.375rem;
        text-align: center;
        margin-bottom: 0.8em;
      }
      p{
        margin-bottom: 0;
      }
    }
  }
}


  .top_title{
    margin-bottom: 0.5em;
  }
  .top_section{
    padding: 4.5em 0;
    .wrapper{
      width: auto;
    }
  }

  #recruit{
    padding-top: 25vw;
    position: relative;
    .photo_area{
      //width: 900px;
      //left: -30px;
      //width: 121vw;
      //left: 1.8vw;
      width: 134vw;
      left: -8vw;
      translate: 0;
    }
    .recruit_intro{
      //padding: 200px 0 220px;
      padding: 25vw 0 220px;
      .wrapper{
        display: block;
      }
      .circle01{
        width: 600px;
        top: 0;
        right: auto;
        left: 0;
        translate: -50% -50%;
      }
      .circle02{
        width: 120px;
        top: 100px;
        left: calc(50% + 10px);
      }
      .circle03{
        width: 400px;
        left: auto;
        right: 0;
        translate: 50% 50%;
      }
      .top_title{
        display: flex;
      }
      .copy_area{
        width: auto;
        .larger{
          font-size: 1.6875rem;
        }
      }
    }
  }

  .recruit_links{
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(4, max-content); 
    gap: 20px; 
    grid-template-areas: 
      "link01 link02"
      "link03 link03"
      "link04 link04"
      "link05 link05";
    &.top{
      margin-top: -20vw;
    }
    .large_link{
      font-size: 5.5vw;
    }
    .small_link{
      aspect-ratio: initial;
      height: 145px;
      font-size: 1.6rem;
      a{
        flex-direction: row;
        justify-content: space-between;
      }
      .fukidashi{
        display: block;
        width: 40%;
        height: 100%;
        padding-top: 0;
        img{
          position: absolute;
          top: 50%;
          right: 0;
          width: 80%;
          translate: 10% -45%;
        }
        &:after{
          bottom: 0;
          left: auto;
          right: 0;
          width: 40px;
          height: 100%;
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }
      }
      .link_title{
        position: static;
        display: flex;
        align-items: center;
        width: 60%;
        padding: 0 0 0 3.5em;
      }
    }
  }


  /* footer
  ====================================================================================================================================== */
  #footer {
    .bg_black{
      padding: 8em 0 3em;
    }
    .info_area{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 4em;
      margin-bottom: 6em;
      .company_info{
        address{
          a{
            color: #fff;
          }
        }
      }
      .button_area{
        width: 400px;
        a{
          height: 7.6em;
          > span{
            padding: 0 1.8em 0 1em;
          }
          .en{
            font-size: 2.6em;
          }
        }
      }
    }
    .company_info{
      text-align: center;
      .company_name{
        img{
          width: 245px;
        }
      }
    }
    .sitemap{
      grid-auto-flow: column;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
      margin-bottom: 70px;
    }
    .sitemap_block{
      dl{
        padding: 1.5em 0;
        dd{
          padding-top: 0.4em;
        }
      }
    }

    .footer_links{
      row-gap: 1em;
    }

    .footer_bottom{
      padding: 2.5em 0 1.7em;
      .footer_banners{
        gap: 35px;
        margin-bottom: 1.5em;
        .logo_egg{
          width: 98px;
        }
        .logo_scala{
          width: 107px;
        }
        .logo_jpx{
          width: 57px;
        }
      }
      .group_info{
        font-size: 0.9rem;
      }
      .copyright{
        display: block;
        font-size: 0.9rem;
      }
    }
  }


  /* button_pagetop
  ====================================================================================================================================== */
  #button_pagetop {
    width: 40px;
    right: 22px;
    bottom: 75px;
  }


}

@media screen and (max-width: 540px) {


  /* 中ページ
  ====================================================================================================================================== */
  #page_title_wrap{
    background-size: $tile_size_sp $tile_size_sp;
    min-height: calc(#{$tile_size_sp} * 3);
    padding-top: 16vw;
  }
  #navibar{
    padding-left: calc(#{$tile_size_sp} * 2 + 10px);
  }

  /* ページ内リンク
  ====================================================================================================================================== */
  #page_links{
    ul{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1em;
      row-gap: 0.4em;
      padding: 0 25px;
    }
  }


  /* header
  ====================================================================================================================================== */
  #site_title {
    width: calc(#{$tile_size_sp} * 2);
    height: $tile_size_sp;
    font-size: 5.2vw;
  }


  /* スマホ用メニュー
  ====================================================================================================================================== */
  #sp_menu_area{
    right: 16px;
  }

/* globalnavi
====================================================================================================================================== */
#globalnavi{
  ul.nav_list{
    grid-template-columns: repeat(3, 1fr);
    li{
      font-size: 5.7vw;
      .inner{
        background-position: right 7px bottom 7px;
        background-size: 14px auto;
      }
    }
    &:after{
      display: none;
    }
  }
  .sp_menu_close{
    padding-right: 16px;
  }
}

/* トップページ loading
====================================================================================================================================== */
#loading,
#main_visual{
  background-size: $tile_size_sp $tile_size_sp;
}
#loading_catch_area,
#main_visual{
  padding-bottom: 95px;
  .catch_copy{
    .en{
      letter-spacing: normal;
    }
    .ja{
      font-size: 3.6vw;
      span{
        padding-left: 3.8em;
        &:after{
          width: 3em;
        }
      }
    }
  }
}

/* トップページ main visual
====================================================================================================================================== */
#main_visual{
  .circle_text{
    translate: 50% 25%;
  }
}

  /* contents
====================================================================================================================================== */
#conts{
  padding-bottom: 100px;
}

#solutions{
  padding-bottom: 95vw;
  $solution_radius_sp: 62vw;
  .solutions_title_area{
    width: #{$solution_radius_sp * 2};
    .bottom_text{
      right: calc(100% - 38vw);
      top: -14vw;
      font-size: 38vw;
    }
    h2{
      font-size: 7.2vw;
      top: 6em;
      left: 4em;
    }
    ul{
      li{
        font-size: 4.7vw;
        &:nth-child(1){
          left: calc(cos(-5deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
          bottom: calc(sin(-5deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
        }
        &:nth-child(2){
          left: calc(cos(-29deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
          bottom: calc(sin(-29deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
        }
        &:nth-child(3){
          left: calc(cos(-53deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
          bottom: calc(sin(-53deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
        }
        &:nth-child(4){
          left: calc(cos(-77deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
          bottom: calc(sin(-77deg) * #{$solution_radius_sp} * -1 + #{$solution_radius_sp});
        }
      }
    }
  }
}

.top_title{
  font-size: 1.375rem;
  margin-bottom: 1.2em;
  .ja{
    font-size: 0.9em;
  }
}

#service{
  border-top: 1px solid $origin_color;
  .wrapper{
    display: block;
  }
  .side_area {
    display: block;
    padding: 8em 0 4em;
    border-right: none;
    position: relative;
    &:before{
      content: "";
      display: block;
      width: 270px;
      aspect-ratio: 600/685;
      background: url(../images/japan.png) no-repeat center bottom;
      background-size: contain;
      position: absolute;
      bottom: -30px;
      right: -40px;
    }
    .top_title{
      display: flex;
      align-items: center;
    }
    .lead_text{
      display: block;
      position: static;
      &:before{
        display: none;
      }
      p{
        width: auto;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
  .service_link{
    a, .no_link{
      padding: 120px 30px 0;
    }
    .num{
      font-size: 1.1875rem;
    }
    .english_title{
      font-size: 8rem;
      right: 50%;
      translate: 50%;
    }
    .text_area{
      h3{
        font-size: 2rem;
      }
    }
  }
}

#recruit{
  padding-top: 30vw;
  .photo_area{
    left: -12vw;
    width: 192vw;
  }
  .recruit_intro{
    //padding: 240px 0 150px;
    padding: 55vw 0 150px;
    .wrapper{
      display: block;
    }
    .circle01{
      width: 390px;
    }
    .circle02{
      width: 95px;
      top: 85px;
      left: calc(50% + 10px);
    }
    .circle03{
      width: 310px;
    }
    .top_title{
      display: flex;
    }
    .copy_area{
      width: auto;
      .larger{
        font-size: 1.5rem;
      }
    }
  }
}

#recruit_bottom{
  margin-top: 130px;
  h2{
    font-size: 1.7rem;
    margin-bottom: 1.4em;
  }
}
  .recruit_links{
    gap: 16px; 
    &.top{
      margin-top: -26vw;
    }
    .large_link{
      font-size: 5vw;
      a{
        padding: 0.7em;
        &:after{
          width: 0.73em;
          right: 6px;
          bottom: 6px;
        }
      }
    }
    .small_link{
      height: 140px;
      font-size: 1.4375rem;
      line-height: 1.7;
      a{
        &:after{
          width: 0.8em;
          bottom: 8px;
          right: 8px;
        }
      }
      .fukidashi{
        background-size: 8px 8px;
        img{
          max-width: initial;
          width: 105%;
          translate: 20% -45%;
        }
      }
      .link_title{
        padding: 0 0 0 3em;
      }
    }
  }



  /* footer
  ====================================================================================================================================== */
  #footer {
    .bg_black{
      padding: 6em 0 3em;
    }
    .info_area{
      gap: 4em;
      margin-bottom: 5em;
      .button_area{
        width: unquote('min(100%, 330px)');
      }
    }
    .company_info{
      .company_name{
        img{
          width: 200px;
        }
      }
    }
    .sitemap{
      gap: 20px;
    }
    .sitemap_block{
      dl{
        padding: 1.3em 0;
        dd{
          padding-top: 0.3em;
        }
      }
    }
    .footer_bottom{
      padding-bottom: 6em;
      .footer_banners{
        gap: 25px;
        .logo_egg{
          width: 95px;
        }
        .logo_scala{
          width: 105px;
        }
        .logo_jpx{
          width: 55px;
        }
      }
    }
  }

  /* button_pagetop
====================================================================================================================================== */
  #button_pagetop{
    width: 35px;
    right: 20px;
  }

}


